<template>
  <div class="contact">
    <BusinessCard/>
  </div>
</template>

<script>
// @ is an alias to /src

import BusinessCard from "@/components/BusinessCard";

export default {
  name: 'ContactView',
  components: {
    BusinessCard
  }
}
</script>