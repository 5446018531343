<template>
  <v-container>
    <v-dialog
        v-model="qrDialog"
        width="360"
    >
      <v-card>
        <canvas ref="qrCanvas"></canvas>
      </v-card>
    </v-dialog>

    <v-row no-gutters>
      <v-col xs="1"/>
      <v-col xs="10">
        <v-card max-width="360">
          <v-img
              src="../assets/header.png"
              height="150px"
              @click="goToHomepage()"
          ></v-img>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <div class="text-block s-2 colorful"><span>
                    {{ fullName }}</span></div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <div class="text-icon s-3"><span><v-icon>mdi-card-account-details</v-icon></span></div>
                </v-col>
                <v-col cols="10">
                  <div class="text-block-bright s-3"><span>{{ cardData.role }}</span></div>
                </v-col>
              </v-row>
              <v-row v-if="hasEmail">
                <v-col cols="2">
                  <div class="text-icon s-3"><span><v-icon>mdi-email</v-icon></span></div>
                </v-col>
                <v-col cols="10">
                  <div class="text-block-bright s-3"><span><a class="link" :href="getMailLink()">{{
                      cardData.email
                    }}</a></span>
                  </div>
                </v-col>
              </v-row>
              <v-row v-for="phoneNumber in this.cardData.phoneNumbers" v-bind:key="phoneNumber.number">
                <v-col cols="2">
                  <div class="text-icon s-3"><span><v-icon>{{ getPhoneIconFor(phoneNumber) }}</v-icon></span></div>
                </v-col>
                <v-col cols="10">
                  <div class="text-block-bright s-3">
                    <span>
                      {{ phoneNumber.number }}
                      &nbsp;
                      <v-btn small :href="getCallLink(phoneNumber)" style="margin-top:-2px">
                        <v-icon left>mdi-cellphone-sound</v-icon>
                        Call
                      </v-btn>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <v-container class="spacer-top">
              <v-row v-for="socialAccount in this.cardData.social" v-bind:key="socialAccount.type">
                <v-col cols="2">
                  <div class="text-icon s-3"><span><v-icon>{{ getSocialIconFor(socialAccount) }}</v-icon></span></div>
                </v-col>
                <v-col cols="10" align="left">
                  <v-btn :href="socialAccount.url" target="_blank" small text style="margin-top: 2px">
                    <v-icon>mdi-open-in-new</v-icon>
                    &nbsp;
                    {{ getSocialLinkNameFor(socialAccount) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-btn class="action" @click="showQrCode()" icon>
              <v-icon>mdi-qrcode</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="action" :href="getVcfLink()" text>
              <v-icon left>mdi-plus-box</v-icon>
              Add to contacts
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col xs="1"/>
    </v-row>
  </v-container>
</template>

<script>
import QRCode from "qrcode"
import properties from "@/properties/properties";
import Vue from "vue";

export default {
  name: "BusinessCard",
  data() {
    return {
      qrDialog: false,
      cardData: {
        name: {
          firstName: "N/A",
          lastName: "N/A"
        },
        role: "N/A"
      },
    }
  },
  mounted() {
    this.axios.get(this.$route.params.token)
        .then(result => {
          this.cardData = result.data
          document.title = "Business Card of " + this.fullName
        })
  },
  computed: {
    fullName() {
      if (this.cardData.name) {
        return this.cardData.name.firstName + " " + this.cardData.name.lastName
      }
      return "N/A"
    },
    hasEmail() {
      return this.cardData.email !== null
    }
  },
  methods: {
    showQrCode() {
      this.qrDialog = true
      Vue.nextTick(() => this.generateQrCode())
    },
    generateQrCode() {
      let canvas = this.$refs.qrCanvas
      QRCode.toCanvas(canvas, window.location.href, {
        errorCorrectionLevel: "L",
        scale: 8,
      }, error => {
        if (error !== null) {
          console.log("Problem with qr code generation", error)
        }
      })
    },
    getMailLink() {
      return "mailto:" + this.cardData.email
    },
    getPhoneIconFor(phoneNumber) {
      if (phoneNumber.type.includes('CELL')) {
        return 'mdi-cellphone'
      }
      return 'mdi-phone'
    },
    getCallLink(phoneNumber) {
      return "tel:" + phoneNumber.number
          .replaceAll(/\([0-9]+\)/ig, '')
          .replaceAll(/[^0-9^\\+]+/ig, '')
    },
    getSocialIconFor(social) {
      switch (social.type) {
        case 'TWITTER':
        case 'X':
          return "$x"
        case 'LINKEDIN':
          return "mdi-linkedin"
        case 'INSTAGRAM':
          return "mdi-instagram"
        case 'GITHUB':
          return "mdi-github"
        case 'DISCORD':
          return "$discord"
      }
      return "mdi-account-multiple"
    },
    getSocialLinkNameFor(social) {
      switch (social.type) {
        case 'TWITTER':
          return social.url.replace(/https:\/\/(?:www\.)?twitter\.com\/(.+)/gm, '$1 on X')
        case 'X':
          return social.url.replace(/https:\/\/(?:www\.)?x\.com\/(.+)/gm, '$1 on X')
        case 'GITHUB':
          return social.url.replace(/https:\/\/(?:www\.)?github\.com\/(.+)/gm, '$1 on github')
        case 'LINKEDIN':
          return this.cardData.name.firstName + ' on LinkedIn'
        case 'INSTAGRAM':
          return this.cardData.name.firstName + ' on Instagram'
        case 'TIKTOK':
          return this.cardData.name.firstName + ' on TikTok'
        case 'DISCORD':
          return social.url.replace(/https:\/\/(?:www\.)?discord\.com\/users\/(.+)/gm, '$1 on discord')
      }
      return social.url
    },
    getVcfLink() {
      return properties.baseURL + this.$route.params.token + "/vcf"
    },
    goToHomepage() {
      window.open(properties.websiteURL, '_newtab');
    }
  }
}
</script>

<style scoped>

a.link {
  text-decoration: underline;
  font-size: 11pt;
  color: #000000;
}

a.link:visited {
  text-decoration: underline;
  color: #000000;
}

a.link:hover {
  background-image: linear-gradient(90deg, #d1cb29, #39b992);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.action {
  color: #39b992;
}

div.text-block-bright span {
  font-family: MobiusCode, serif;
  display: block;
  margin-top: 2px;
  text-align: left;
  padding: 2px 8px;
  font-size: 1rem !important;
}

div.text-block {
  background-color: #242424;
  margin-top: 0;
}

div.text-block span {
  font-family: MobiusCode, serif;
  display: block;
  margin-top: 2px;
  text-align: left;
  padding: 8px 8px;
  color: #ffffff;
}

div.text-icon {
  background-color: #242424;
  margin-top: 0;
}

div.text-icon span {
  font-family: MobiusCode, serif;
  display: block;
  margin-top: 2px;
  text-align: center;
  /*padding: 2px 8px;*/
  padding-top: 2px;
  padding-bottom: 2px;
  color: #ffffff;
}

div.text-icon span i {
  color: #ffffff;
}


div.s-1 span {
  font-size: 28pt;
}

div.s-2 span {
  font-size: 20pt;
}

div.s-3 span {
  font-size: 12pt;
}

div.colorful span {
  background-image: linear-gradient(90deg, #d1cb29, #39b992);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.spacer-top {
  margin-top: 12px;
}

.v-btn {
  padding: 4px !important;
}

.col {
  padding: 4px !important;
}

.text-icon {
  padding: 0;
}

</style>