import Vue from 'vue'
import VueRouter from 'vue-router'
import ContactView from '../views/ContactView.vue'
import NotFoundView from '../views/NotFoundView'
import axios from "@/utils/axios"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'NotFound',
        component: NotFoundView
    },
    {
        path: '/:token([a-zA-Z0-9\\-_]{8})',
        name: 'contact',
        component: ContactView
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }

]

const router = new VueRouter({
    routes: routes,
    mode: 'history',
})

router.beforeEach(async (to, from, next) => {
    if (to.name !== 'contact') {
        next()
        return
    }

    try {
        let result = await axios.head(to.params.token)
        if (result.status === 200) {
            next()
            return
        }
    } catch (ex) {
        console.log("does not exist")
    }
    return next({name: 'NotFound'})
})

export default router
